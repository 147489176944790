/* tslint:disable */
/* eslint-disable */
/**
 * Giphy
 * Giphy API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@giphy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gif,
    GifFromJSON,
    GifFromJSONTyped,
    GifToJSON,
    Meta,
    MetaFromJSON,
    MetaFromJSONTyped,
    MetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse2001
 */
export interface InlineResponse2001 {
    /**
     * 
     * @type {Gif}
     * @memberof InlineResponse2001
     */
    data?: Gif;
    /**
     * 
     * @type {Meta}
     * @memberof InlineResponse2001
     */
    meta?: Meta;
}

export function InlineResponse2001FromJSON(json: any): InlineResponse2001 {
    return InlineResponse2001FromJSONTyped(json, false);
}

export function InlineResponse2001FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse2001 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : GifFromJSON(json['data']),
        'meta': !exists(json, 'meta') ? undefined : MetaFromJSON(json['meta']),
    };
}

export function InlineResponse2001ToJSON(value?: InlineResponse2001 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': GifToJSON(value.data),
        'meta': MetaToJSON(value.meta),
    };
}


