/* tslint:disable */
/* eslint-disable */
/**
 * Giphy
 * Giphy API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@giphy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    GifImages,
    GifImagesFromJSON,
    GifImagesFromJSONTyped,
    GifImagesToJSON,
    User,
    UserFromJSON,
    UserFromJSONTyped,
    UserToJSON,
} from './';

/**
 * 
 * @export
 * @interface Gif
 */
export interface Gif {
    /**
     * The unique bit.ly URL for this GIF
     * @type {string}
     * @memberof Gif
     */
    bitlyUrl?: string;
    /**
     * Currently unused
     * @type {string}
     * @memberof Gif
     */
    contentUrl?: string;
    /**
     * The date this GIF was added to the GIPHY database.
     * @type {Date}
     * @memberof Gif
     */
    createDatetime?: Date;
    /**
     * A URL used for embedding this GIF
     * @type {string}
     * @memberof Gif
     */
    embdedUrl?: string;
    /**
     * An array of featured tags for this GIF (Note: Not available when using the Public Beta Key)
     * @type {Array<string>}
     * @memberof Gif
     */
    featuredTags?: Array<string>;
    /**
     * This GIF's unique ID
     * @type {string}
     * @memberof Gif
     */
    id?: string;
    /**
     * 
     * @type {GifImages}
     * @memberof Gif
     */
    images?: GifImages;
    /**
     * The creation or upload date from this GIF's source.
     * @type {Date}
     * @memberof Gif
     */
    importDatetime?: Date;
    /**
     * The MPAA-style rating for this content. Examples include Y, G, PG, PG-13 and R
     * @type {string}
     * @memberof Gif
     */
    rating?: string;
    /**
     * The unique slug used in this GIF's URL
     * @type {string}
     * @memberof Gif
     */
    slug?: string;
    /**
     * The page on which this GIF was found
     * @type {string}
     * @memberof Gif
     */
    source?: string;
    /**
     * The URL of the webpage on which this GIF was found.
     * @type {string}
     * @memberof Gif
     */
    sourcePostUrl?: string;
    /**
     * The top level domain of the source URL.
     * @type {string}
     * @memberof Gif
     */
    sourceTld?: string;
    /**
     * An array of tags for this GIF (Note: Not available when using the Public Beta Key)
     * @type {Array<string>}
     * @memberof Gif
     */
    tags?: Array<string>;
    /**
     * The date on which this gif was marked trending, if applicable.
     * @type {Date}
     * @memberof Gif
     */
    trendingDatetime?: Date;
    /**
     * Type of the gif. By default, this is almost always gif
     * @type {string}
     * @memberof Gif
     */
    type?: GifTypeEnum;
    /**
     * The date on which this GIF was last updated.
     * @type {Date}
     * @memberof Gif
     */
    updateDatetime?: Date;
    /**
     * The unique URL for this GIF
     * @type {string}
     * @memberof Gif
     */
    url?: string;
    /**
     * 
     * @type {User}
     * @memberof Gif
     */
    user?: User;
    /**
     * The username this GIF is attached to, if applicable
     * @type {string}
     * @memberof Gif
     */
    username?: string;
}

export function GifFromJSON(json: any): Gif {
    return GifFromJSONTyped(json, false);
}

export function GifFromJSONTyped(json: any, ignoreDiscriminator: boolean): Gif {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bitlyUrl': !exists(json, 'bitly_url') ? undefined : json['bitly_url'],
        'contentUrl': !exists(json, 'content_url') ? undefined : json['content_url'],
        'createDatetime': !exists(json, 'create_datetime') ? undefined : (new Date(json['create_datetime'])),
        'embdedUrl': !exists(json, 'embded_url') ? undefined : json['embded_url'],
        'featuredTags': !exists(json, 'featured_tags') ? undefined : json['featured_tags'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'images': !exists(json, 'images') ? undefined : GifImagesFromJSON(json['images']),
        'importDatetime': !exists(json, 'import_datetime') ? undefined : (new Date(json['import_datetime'])),
        'rating': !exists(json, 'rating') ? undefined : json['rating'],
        'slug': !exists(json, 'slug') ? undefined : json['slug'],
        'source': !exists(json, 'source') ? undefined : json['source'],
        'sourcePostUrl': !exists(json, 'source_post_url') ? undefined : json['source_post_url'],
        'sourceTld': !exists(json, 'source_tld') ? undefined : json['source_tld'],
        'tags': !exists(json, 'tags') ? undefined : json['tags'],
        'trendingDatetime': !exists(json, 'trending_datetime') ? undefined : (new Date(json['trending_datetime'])),
        'type': !exists(json, 'type') ? undefined : json['type'],
        'updateDatetime': !exists(json, 'update_datetime') ? undefined : (new Date(json['update_datetime'])),
        'url': !exists(json, 'url') ? undefined : json['url'],
        'user': !exists(json, 'user') ? undefined : UserFromJSON(json['user']),
        'username': !exists(json, 'username') ? undefined : json['username'],
    };
}

export function GifToJSON(value?: Gif | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bitly_url': value.bitlyUrl,
        'content_url': value.contentUrl,
        'create_datetime': value.createDatetime === undefined ? undefined : (value.createDatetime.toISOString()),
        'embded_url': value.embdedUrl,
        'featured_tags': value.featuredTags,
        'id': value.id,
        'images': GifImagesToJSON(value.images),
        'import_datetime': value.importDatetime === undefined ? undefined : (value.importDatetime.toISOString()),
        'rating': value.rating,
        'slug': value.slug,
        'source': value.source,
        'source_post_url': value.sourcePostUrl,
        'source_tld': value.sourceTld,
        'tags': value.tags,
        'trending_datetime': value.trendingDatetime === undefined ? undefined : (value.trendingDatetime.toISOString()),
        'type': value.type,
        'update_datetime': value.updateDatetime === undefined ? undefined : (value.updateDatetime.toISOString()),
        'url': value.url,
        'user': UserToJSON(value.user),
        'username': value.username,
    };
}

/**
* @export
* @enum {string}
*/
export enum GifTypeEnum {
    Gif = 'gif'
}


