/* tslint:disable */
/* eslint-disable */
/**
 * Giphy
 * Giphy API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@giphy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    InlineResponse200,
    InlineResponse200FromJSON,
    InlineResponse200ToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
} from '../models';

export interface GetGifByIdRequest {
    gifId: number;
}

export interface GetGifsByIdRequest {
    ids?: string;
}

export interface RandomGifRequest {
    tag?: string;
    rating?: string;
}

export interface SearchGifsRequest {
    q: string;
    limit?: number;
    offset?: number;
    rating?: string;
    lang?: string;
}

export interface TranslateGifRequest {
    s: string;
}

export interface TrendingGifsRequest {
    limit?: number;
    offset?: number;
    rating?: string;
}

/**
 * 
 */
export class GifsApi extends runtime.BaseAPI {

    /**
     * Returns a GIF given that GIF\'s unique ID 
     * Get GIF by Id
     */
    async getGifByIdRaw(requestParameters: GetGifByIdRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.gifId === null || requestParameters.gifId === undefined) {
            throw new runtime.RequiredError('gifId','Required parameter requestParameters.gifId was null or undefined when calling getGifById.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api_key"] = this.configuration.apiKey("api_key"); // api_key authentication
        }

        const response = await this.request({
            path: `/gifs/{gifId}`.replace(`{${"gifId"}}`, encodeURIComponent(String(requestParameters.gifId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Returns a GIF given that GIF\'s unique ID 
     * Get GIF by Id
     */
    async getGifById(requestParameters: GetGifByIdRequest): Promise<InlineResponse2001> {
        const response = await this.getGifByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * A multiget version of the get GIF by ID endpoint. 
     * Get GIFs by ID
     */
    async getGifsByIdRaw(requestParameters: GetGifsByIdRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.ids !== undefined) {
            queryParameters['ids'] = requestParameters.ids;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api_key"] = this.configuration.apiKey("api_key"); // api_key authentication
        }

        const response = await this.request({
            path: `/gifs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * A multiget version of the get GIF by ID endpoint. 
     * Get GIFs by ID
     */
    async getGifsById(requestParameters: GetGifsByIdRequest): Promise<InlineResponse200> {
        const response = await this.getGifsByIdRaw(requestParameters);
        return await response.value();
    }

    /**
     * Returns a random GIF, limited by tag. Excluding the tag parameter will return a random GIF from the GIPHY catalog. 
     * Random GIF
     */
    async randomGifRaw(requestParameters: RandomGifRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.tag !== undefined) {
            queryParameters['tag'] = requestParameters.tag;
        }

        if (requestParameters.rating !== undefined) {
            queryParameters['rating'] = requestParameters.rating;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api_key"] = this.configuration.apiKey("api_key"); // api_key authentication
        }

        const response = await this.request({
            path: `/gifs/random`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * Returns a random GIF, limited by tag. Excluding the tag parameter will return a random GIF from the GIPHY catalog. 
     * Random GIF
     */
    async randomGif(requestParameters: RandomGifRequest): Promise<InlineResponse2001> {
        const response = await this.randomGifRaw(requestParameters);
        return await response.value();
    }

    /**
     * Search all GIPHY GIFs for a word or phrase. Punctuation will be stripped and ignored.  Use a plus or url encode for phrases. Example paul+rudd, ryan+gosling or american+psycho. 
     * Search GIFs
     */
    async searchGifsRaw(requestParameters: SearchGifsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling searchGifs.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.rating !== undefined) {
            queryParameters['rating'] = requestParameters.rating;
        }

        if (requestParameters.lang !== undefined) {
            queryParameters['lang'] = requestParameters.lang;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api_key"] = this.configuration.apiKey("api_key"); // api_key authentication
        }

        const response = await this.request({
            path: `/gifs/search`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Search all GIPHY GIFs for a word or phrase. Punctuation will be stripped and ignored.  Use a plus or url encode for phrases. Example paul+rudd, ryan+gosling or american+psycho. 
     * Search GIFs
     */
    async searchGifs(requestParameters: SearchGifsRequest): Promise<InlineResponse200> {
        const response = await this.searchGifsRaw(requestParameters);
        return await response.value();
    }

    /**
     * The translate API draws on search, but uses the GIPHY `special sauce` to handle translating from one vocabulary to another. In this case, words and phrases to GIF 
     * Translate phrase to GIF
     */
    async translateGifRaw(requestParameters: TranslateGifRequest): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.s === null || requestParameters.s === undefined) {
            throw new runtime.RequiredError('s','Required parameter requestParameters.s was null or undefined when calling translateGif.');
        }

        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.s !== undefined) {
            queryParameters['s'] = requestParameters.s;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api_key"] = this.configuration.apiKey("api_key"); // api_key authentication
        }

        const response = await this.request({
            path: `/gifs/translate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     * The translate API draws on search, but uses the GIPHY `special sauce` to handle translating from one vocabulary to another. In this case, words and phrases to GIF 
     * Translate phrase to GIF
     */
    async translateGif(requestParameters: TranslateGifRequest): Promise<InlineResponse2001> {
        const response = await this.translateGifRaw(requestParameters);
        return await response.value();
    }

    /**
     * Fetch GIFs currently trending online. Hand curated by the GIPHY editorial team.  The data returned mirrors the GIFs showcased on the GIPHY homepage. Returns 25 results by default. 
     * Trending GIFs
     */
    async trendingGifsRaw(requestParameters: TrendingGifsRequest): Promise<runtime.ApiResponse<InlineResponse200>> {
        const queryParameters: runtime.HTTPQuery = {};

        if (requestParameters.limit !== undefined) {
            queryParameters['limit'] = requestParameters.limit;
        }

        if (requestParameters.offset !== undefined) {
            queryParameters['offset'] = requestParameters.offset;
        }

        if (requestParameters.rating !== undefined) {
            queryParameters['rating'] = requestParameters.rating;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            queryParameters["api_key"] = this.configuration.apiKey("api_key"); // api_key authentication
        }

        const response = await this.request({
            path: `/gifs/trending`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse200FromJSON(jsonValue));
    }

    /**
     * Fetch GIFs currently trending online. Hand curated by the GIPHY editorial team.  The data returned mirrors the GIFs showcased on the GIPHY homepage. Returns 25 results by default. 
     * Trending GIFs
     */
    async trendingGifs(requestParameters: TrendingGifsRequest): Promise<InlineResponse200> {
        const response = await this.trendingGifsRaw(requestParameters);
        return await response.value();
    }

}
