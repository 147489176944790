/* tslint:disable */
/* eslint-disable */
/**
 * Giphy
 * Giphy API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@giphy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * The Meta Object contains basic information regarding the request, whether it was successful, and the response given by the API.  Check `responses` to see a description of types of response codes the API might give you under different cirumstances.
 * @export
 * @interface Meta
 */
export interface Meta {
    /**
     * HTTP Response Message
     * @type {string}
     * @memberof Meta
     */
    msg?: string;
    /**
     * A unique ID paired with this response from the API.
     * @type {string}
     * @memberof Meta
     */
    responseId?: string;
    /**
     * HTTP Response Code
     * @type {number}
     * @memberof Meta
     */
    status?: number;
}

export function MetaFromJSON(json: any): Meta {
    return MetaFromJSONTyped(json, false);
}

export function MetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): Meta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'msg': !exists(json, 'msg') ? undefined : json['msg'],
        'responseId': !exists(json, 'response_id') ? undefined : json['response_id'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function MetaToJSON(value?: Meta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'msg': value.msg,
        'response_id': value.responseId,
        'status': value.status,
    };
}


