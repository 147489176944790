/* tslint:disable */
/* eslint-disable */
/**
 * Giphy
 * Giphy API
 *
 * The version of the OpenAPI document: 1.0
 * Contact: support@giphy.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Gif,
    GifFromJSON,
    GifFromJSONTyped,
    GifToJSON,
    Meta,
    MetaFromJSON,
    MetaFromJSONTyped,
    MetaToJSON,
    Pagination,
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
} from './';

/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<Gif>}
     * @memberof InlineResponse200
     */
    data?: Array<Gif>;
    /**
     * 
     * @type {Meta}
     * @memberof InlineResponse200
     */
    meta?: Meta;
    /**
     * 
     * @type {Pagination}
     * @memberof InlineResponse200
     */
    pagination?: Pagination;
}

export function InlineResponse200FromJSON(json: any): InlineResponse200 {
    return InlineResponse200FromJSONTyped(json, false);
}

export function InlineResponse200FromJSONTyped(json: any, ignoreDiscriminator: boolean): InlineResponse200 {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(GifFromJSON)),
        'meta': !exists(json, 'meta') ? undefined : MetaFromJSON(json['meta']),
        'pagination': !exists(json, 'pagination') ? undefined : PaginationFromJSON(json['pagination']),
    };
}

export function InlineResponse200ToJSON(value?: InlineResponse200 | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(GifToJSON)),
        'meta': MetaToJSON(value.meta),
        'pagination': PaginationToJSON(value.pagination),
    };
}


